import React from "react";
import "../../styles/gst.css";
import Testimonial from "../../components/Testimonial";
import FAQAccord from "../../components/GFlush";
import Layout from "../../components/Layout";
import { Seo } from "../../components/seo";
import Counter from "../../components/Counter";
import Guidance from "../../components/GSTTemp/Guidance";
// import Hero from "../../components/Hero";
import AboutWithForm from "../../components/GSTTemp/AboutWithForm";
import ScrollNav from "../../components/GSTTemp/ScrollNav";
import ImgContent from "../../components/GSTTemp/ImgContent";
import ContentForm from "../../components/GSTTemp/ContentForm";
import Cta from "../../components/Cta";
import SliderContent from "../../components/GSTTemp/SliderContent";
import ContentImg from "../../components/GSTTemp/ContentImg";
import Package from "../../components/GSTTemp/Package";

export default function InternationalTrademarkRegistration() {
  /* Slider */
  // const markSlider = [
  //   {
  //     header_color: false,
  //     content_color: false,
  //     background: `linear-gradient(139.4deg, rgb(207, 249, 216) 28.54%, rgb(243, 238, 160) 104.43%)`,
  //     header: `International Trademark Registration`,
  //     content: `Secure design Registration for Your Products and Brands`,
  //     image: "/imgs/registration/ngo/12aa-registration.png",
  //     alt_tag: "Best International Trademark Registration Near Hosur",
  //   },
  // ];

  /* Package Data */
  const PackageCompData = {
    header: `International Trademark Registration`,
    buyBtnLink: `#pricing-buy`,
    price: `44999`,
    content: [
      {
        points: `PAN & TAN Registration `,
        icon: "/imgs/icons/gst.png",
        alt_tag: "PAN & TAN Registration",
        background: "",
        plus: "+",
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: "/imgs/icons/itr_filing.png",
        alt_tag: "TODAYFILINGS ITR E-filing service",
        background: "bg-f78acb",
        plus: "+",
        plus_dnone: "d-none d-md-block",
      },
      {
        points: `MSME Registration`,
        icon: "/imgs/icons/enterprises.png",
        alt_tag: "MSME Registration",
        background: "bg-eb6b3d",
        plus: "+",
      },
      {
        points: `Tax Consultancy Service `,
        icon: "/imgs/icons/consultant.png",
        alt_tag: "Tax Consultancy Service",
        background: "bg-c679e3",
      },
    ],
  };
  /* About Data With Form*/
  const markAboutData = {
    header: `International Trademark Registration`,
    sub_title: `Online trademark registration for the international business`,
    content_paragraph: [
      `Trademark is a unique term or symbol that distinguishes a good or service from rival products on 
      the market.The trademark registration procedure is subject to regional limitations. To be more precise, for each country 
      or region where you need to indicate the presence of your business or product, you must submit a separate 
      trademark registration application. `,
    ],
  };

  /* Scroll Nav Data */
  const markScrollId = [
    {
      id: `#checklist`,
      heading: `Overview`,
    },
    {
      id: `#procedure`,
      heading: `Procedure`,
    },
    {
      id: `#requirements`,
      heading: `Requirements`,
    },
    {
      id: `#benefits`,
      heading: `Benefits`,
    },
  ];

  /* Img Content Component Data */
  const markIcData = {
    id: 'checklist',
    mt_div: 'mt-3',
    mt_img: '',
    header: 'Overview Of International Trademark Registration',
    image: '/imgs/registration/ipr/td2.png',
    alt_tag: 'International trademark registration',
    Paragraph: `The process for registering trademarks internationally is described in the Madrid Protocol or 
    Agreement. You only need to submit an application for international trademark registration through the 
    relevant trademark office of the applicant. The Madrid Protocol was developed in 1996 with the primary
     goal of registering a trademark across numerous nations.`,
    points: [
      {
        head: '',
        content: `Applications for international trademark registration must be filed through the applicant's 
        trademark office. Office of Registrar of Trademarks in India is the site of origin if the trademark 
        registration is done for any Indian business.`,
        icon: true,
      },
      {
        head: '',
        content: `The Intellectual Property Organization, based in Geneva,
        keeps track of trademark applications through the office of origin.`,
        icon: true,
      },
      {
        head: '',
        content: `The Madrid Protocol has been ratified by 94 nations, making every international trademark
         eligible for protection there.`,
        icon: true,
      },
      {
        head: '',
        content: `Your mark, logo, phrase, or word will be ready to be put into the 
         International Register and further published in the World Intellectual Property Organization (WIPO)
          Gazette of International Marks.`,
        icon: true,
      },
    ],
  };

  /* Content Form Component Data */
  const markCfData = {
    id: 'procedure',
    mt_div: 'mt-5',
    mt_img: 'mt-5',
    header:
      'The Registration Procedure Of International Trademark Registration',
    content: ``,
    body: [
      {
        head: `Examination by WIPO`,
        points: `The National Trademark Office, an Office of Origin, or a Workplace of 
        Origin may be the best place to submit an application. You must register the mark before submitting an
         international application. An International Trademark registration will then be forwarded to World 
         Intellectual Property Organization (WIPO).`,
        icon: true,
      },
      {
        head: `National Offices Inspection`,
        points: `The duration of this international trademark is ten years. At the conclusion of ten years, you can 
        modify or renew the registration certificate if you'd like. WIPO will mail you a message after drafting the 
        Trademark Offices' selections in the International Register.`,
        icon: true,
      },
      {
        head: `Deadline`,
        points: `No country's processing period is longer than 18 months, though it varies from country to country.`,
        icon: true,
      },
    ],
  };

  /*  Content Img Component Data */
  const markCiData = {
    id: 'benefits',
    background: 'main-content',
    mt_div: '',
    header: 'Advantages Of International Trademark Registration',
    paragraph: ``,
    points: [
      {
        head: 'International trademark registration advantages:',
        content: ``,
      },
      {
        content: `Legal defense.`,
        icon: true,
      },
      {
        content: `Distinct identity.`,
        icon: true,
      },
      {
        content: `Business possibility.`,
        icon: true,
      },
      {
        content: `Adaptations and renewal.`,
        icon: true,
      },
      {
        content: `Advantageous for online business owners.`,
        icon: true,
      },
      {
        content: `During export and import your brand is protected.`,
        icon: true,
      },
    ],
    image: '/imgs/business/llp.png',
    alt_tag: 'International trademark registration',
  };

  /* ImgSlider Data */
  const designImages = [
    '/imgs/business/form-filing.png',
    '/imgs/business/responsible.png',
    '/imgs/business/duties.png',
  ];

  /*  Slider Content Component Data */
  const markSCData = {
    id: 'requirements',
    background: [],
    mt_div: 'mt-3',
    header: 'Basic Requirements To Register A Trademark Internationally',
    content: [
      {
        head: 'You must meet the following conditions in order to file the international registration.',
        points: `Possess a company presence in a contracting party`,
        icon: true,
      },
      {
        points: `Holding a residence in a Contracting Party`,
        icon: true,
      },
      {
        points: `For instance, if you are an Australian citizen or business owner and your association is 
        from Canada, you fall under the Madrid System and must submit your registration application through the Canadian Intellectual Property (IP) Office. `,
        icon: true,
      },
    ],
    images: designImages,
  };

  var FAQ_data = [
    {
      header: 'What is Madrid Protocol?',
      body: [
        {
          content: `A coordinated framework for overseeing international trademark
          registration.`,
          icon: false,
        },
      ],
    },
    {
      header:
        'What are the requirements for international trademark registration?',
      body: [
        {
          content: `The applicant should be domiciled in India, be an Indian national or have an effective and real 
          business or commercial establishment in India. The applicant must have a trademark registered with the Indian 
          Trademarks Registry or must have applied for a national trademark application in India`,
          icon: false,
        },
      ],
    },
    {
      header: 'Can a trademark be registered internationally?',
      body: [
        {
          content: `At the international level, you have two options: either you can file a trademark application with
           the trademark office of each country in which you are seeking protection, or you can use WIPO's Madrid System.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Do I need to trademark in multiple countries?',
      body: [
        {
          content: `Trademark rights are protected 'country by country'. You must apply for a registered
           trademark in each country that you want protection in.`,
          icon: false,
        },
      ],
    },
    {
      header: 'How do you protect trademarks internationally?',
      body: [
        {
          content: `To protect the trademark internationally, you first need to register it overseas, and registering
           it with the USPTO is vital. US registration is quite hassle-free, and after it is done, you can register 
           the same trademark in any other country within 180 days from the date of US registration.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Can a foreign trademark be registered in India?',
      body: [
        {
          content: `On 8th July 2013, our country becomes a member of the Madrid Protocol. This implies that the 
          Trademark application for Foreign nationals is a practical thing as any person/company from a member 
          nation can apply for Trademark registration in India.`,
          icon: false,
        },
      ],
    },

    {
      header: 'What is WIPO?',
      body: [
        {
          content: `Your trademark application will be formally reviewed by WIPO, the
          World Intellectual Property Organization. However, each
          contracting party, country, or domain in which you have filed will
          have the option to review the application in accordance with the
          standards and framework that have been established.`,
          icon: false,
        },
      ],
    },
    {
      header: 'India in the Madrid Convention?',
      body: [
        {
          content: `Yes, India joined the Madrid Convention in 2013 and is a member
          today.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Who may submit an international trademark application?',
      body: [
        {
          content: ` Any individual or company that owns a legitimate and functional
          business or industrial facility.`,
          icon: false,
        },
      ],
    },
    {
      header: `What safeguards do trademarks have?`,
      body: [
        {
          content: `Copyrights, patents, and trademarks are all protected by various
          types of intellectual property (IP). Typically, a trademark protects
          company names and logos, a copyright law protects original creative
          works, and a patent registration protects innovations.`,
          icon: false,
        },
      ],
    },
  ];
  return (
    <div>
      <Seo
        title='International Trademark Registration'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />
      <Layout>
        <main id='main'>
          {/* <Hero sliders={markSlider} /> */}
          <Package packageData={PackageCompData} />

          <AboutWithForm about_content={markAboutData} />
          <Counter />
          <ScrollNav scroll_data={markScrollId} />
          <ImgContent item={markIcData} />
          <ContentForm CFSection_data={markCfData} />
          <SliderContent ScSection_data={markSCData} />
          <ContentImg CISection_data={markCiData} />

          <Cta />
          <Guidance />

          <Testimonial />
          <FAQAccord items={FAQ_data} />
        </main>
      </Layout>
    </div>
  );
}
